import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import css from '@emotion/css'
import { colors, mq } from '../../../styles/variables'
import { Container, SectionHeading, TextTag, Wrap, renderTextTag } from '../helpers/ContainerV2'
import { useSalProps } from '../../../helpers/useSalAnim'
import { getEmSize } from '../../../styles/mixins'
import CardTeam from '../../CardTeam'
import HeadingTopTitle from '../helpers/HeadingTopTitle'

export const Flex = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  '@media (max-width: 768px)': {
    display: 'block'
  }
})

const ContactTextHelper = styled('div')({
  fontSize: 19,
  color: colors.gray.calm,
  fontWeight: 500,
  marginBottom: 10
})

const ContactLink = styled('a')({
  textDecoration: 'none'
})

interface IOutComeProps {
  id: string
  title: string
  description: string[]
  content: any
  subTitle: string
  quote: {
    text: string
    author: string
  }
}

const Story = ({ title, description, subTitle, id, content, quote }: IOutComeProps) => {
  const salProps = useSalProps()

  return (
    <Container backgroundColor="white" id={id}>
      <SectionHeading
        css={{
          color: colors.brand,
          '&:after': {
            backgroundColor: colors.brand
          }
        }}
        {...salProps}
      >
        {title}
      </SectionHeading>
      <Flex
        css={{
          gap: 70,
          alignItems: 'flex-start'
        }}
      >
        <Wrap
          css={css`
            ${mq('md')} {
              max-width: 90%;
            }
          `}
        >
          {subTitle && (
            <h3
              className="subTitle"
              css={{
                color: colors.brand
              }}
              {...salProps}
            >
              {subTitle}
            </h3>
          )}
          {description?.map((text, index) => {
            return (
              <TextTag
                css={{
                  fontSize: `${getEmSize(18)}em`,
                  fontWeight: 300,
                  color: colors.brand,
                  marginTop: 10
                }}
                {...salProps}
                data-sal-anim="200"
                key={index}
              >
                {text}
              </TextTag>
            )
          })}
        </Wrap>
      </Flex>
      <Flex
        css={{
          gap: 70,
          alignItems: 'flex-start'
        }}
      >
        <Wrap
          css={css`
            color: ${colors.brand};
            ${mq('md')} {
              max-width: 90%;
            }
          `}
        >
          {content?.map((contentData: any, index: number) => {
            return (
              <Flex
                key={index}
                css={{
                  columnGap: 30
                }}
              >
                <div
                  css={{
                    flex: contentData?.leftWidth / 12,
                    '@media (max-width: 768px)': {
                      marginBottom: 10
                    }
                  }}
                >
                  {contentData?.left?.map((data: any, index: number) => renderTextTag(data, index, salProps))}
                </div>
                <div
                  css={{
                    flex: contentData?.rightWidth / 12,
                    '@media (max-width: 768px)': {
                      marginBottom: 10
                    }
                  }}
                >
                  {contentData?.right?.map((data: any, index: number) => {
                    return renderTextTag(data, index, salProps)
                  })}
                </div>
              </Flex>
            )
          })}
        </Wrap>
      </Flex>
    </Container>
  )
}

export default Story
