import * as React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'

import css from '@emotion/css'
import ParallaxBgServices from '../../ParallaxBgServices'
import { colors, widths, mq } from '../../../styles/variables'
import { getEmSize } from '../../../styles/mixins'
import { useSalProps } from '../../../helpers/useSalAnim'
import List from '../helpers/List'
import { CenteredContent, ContainerInner, ContainerWrap, SectionHeading, Wrap, renderTextTag } from '../helpers/ContainerV2'

const Cards = styled(Flex)`
  width: 100%;
  max-width: ${widths.maxOuter}px;
  justify-content: center;
  align-self: center;
  margin-bottom: 150px;
  z-index: 200;
`

interface ActivityItemProps {
  title: string
  icon: string
}

interface ActivityProps {
  id: string
  title: string
  description: string[]
  helperDescription?: string[]
  isList?: boolean
  items: ActivityItemProps[]
  content?: any[]
}

const Activity: React.FC<ActivityProps> = ({ title, description, items, content, helperDescription, isList }) => {
  const salProps = useSalProps()
  return (
    <ContainerWrap
      id="activity"
      css={css`
        color: white;
      `}
    >
      <ParallaxBgServices />
      <ContainerInner>
        <CenteredContent width={[1, 9 / 10, 4 / 5]}>
          <Wrap>
            <SectionHeading {...salProps}>{title}</SectionHeading>
            {description?.map((text, index) => {
              return (
                <p
                  css={{
                    fontSize: `${getEmSize(24)}em`,
                    color: colors.white
                  }}
                  {...salProps}
                  key={index}
                  data-sal-anim="200"
                >
                  {text}
                </p>
              )
            })}

            {helperDescription?.map((text, index) => {
              return (
                <p
                  css={{
                    fontSize: `${getEmSize(20)}em`,
                    color: colors.white,
                    fontWeight: 300
                  }}
                  {...salProps}
                  key={index}
                  data-sal-anim="200"
                >
                  {text}
                </p>
              )
            })}
            {isList && (
              <>
                <ul
                  css={{
                    paddingLeft: '30px !important'
                  }}
                >
                  {items?.map((item, index) => {
                    return (
                      <li
                        css={{
                          fontSize: `${getEmSize(20)}em`,
                          color: colors.white,
                          fontWeight: 300
                        }}
                        {...salProps}
                        key={index}
                        data-sal-anim="200"
                      >
                        {item.title}
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
            {content?.map((item, index) => {
              return renderTextTag(item, index, salProps)
            })}
          </Wrap>
        </CenteredContent>
      </ContainerInner>

      <Cards flexWrap="wrap">{!isList && <List items={items} />}</Cards>
    </ContainerWrap>
  )
}

export default Activity
