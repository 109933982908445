import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { colors } from '../../../styles/variables'
import { Container, SectionHeading, Wrap } from '../helpers/ContainerV2'
import { useSalProps } from '../../../helpers/useSalAnim'
import { getEmSize } from '../../../styles/mixins'
import CardTeam from '../../CardTeam'

export const Flex = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 768px)': {
    display: 'block'
  }
})
const ContactTextHelper = styled('div')({
  fontSize: 19,
  color: colors.gray.calm,
  fontWeight: 500,
  marginBottom: 10
})

const ContactLink = styled('a')({
  textDecoration: 'none'
})

interface IOutComeProps {
  id: string
  title: string
  description: string[]
}
const Dilligence = ({ title, description, id }: IOutComeProps) => {
  const salProps = useSalProps()
  return (
    <Container backgroundColor={colors.skyblue} id={id}>
      <SectionHeading
        css={{
          color: colors.brand,
          '&:after': {
            backgroundColor: colors.brand
          }
        }}
        {...salProps}
      >
        {title}
      </SectionHeading>
      <Wrap>
        {description?.map((text, index) => {
          return (
            <p
              css={{
                fontSize: `${getEmSize(19)}em`,
                color: colors.brand
              }}
              {...salProps}
              data-sal-anim="200"
              key={index}
            >
              {text}
            </p>
          )
        })}
      </Wrap>
    </Container>
  )
}

export default Dilligence
