import * as React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import 'modern-normalize'
import '../../styles/normalize'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import LayoutRoot from '../../components/LayoutRoot'
import LayoutMain from '../../components/LayoutMain'
import SectionPositions from '../../components/SectionPositions'
import SectionContact from '../../components/SectionContact'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      siteUrl: string
      logo: string
    }
  }
}

const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1600px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256]
}

interface ICasestudy {
  title?: string
}
const CaseStudyLayout: React.FC<ICasestudy> = ({ children, title }) => (
  // <StaticQuery
  //   query={graphql`
  //     query IndexLayoutQuery {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //         }
  //       }
  //     }
  //   `}
  //   render={(data: StaticQueryProps) => (
  <ParallaxProvider>
    <ThemeProvider theme={theme}>
      <LayoutRoot>
        <Helmet title={title || 'Case Study'} />
        {/* <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
            { name: 'og:url', content: data.site.siteMetadata.siteUrl },
            { name: 'og:title', content: data.site.siteMetadata.title },
            { name: 'og:image', content: data.site.siteMetadata.logo }
          ]}
        /> */}
        <LayoutMain>{children}</LayoutMain>
        <SectionPositions id="positions" />
        <SectionContact id="contact" />
        <Footer copyright="Copyright" />
      </LayoutRoot>
    </ThemeProvider>
  </ParallaxProvider>
  // )}
  // />
)

export default CaseStudyLayout
