import styled from '@emotion/styled'
import { colors } from '../../../styles/variables'
import React from 'react'
import { useSalProps } from '../../../helpers/useSalAnim'
import chartIcon from '../../../images/icons/chart.svg'
import buildIcon from '../../../images/icons/build.svg'
import gearIcon from '../../../images/icons/gear.svg'
import networkIcon from '../../../images/icons/network.svg'
import officeIcon from '../../../images/icons/office.svg'
import teamIcon from '../../../images/icons/team.svg'

const iconMapper = (icon: string) => {
  switch (icon) {
    case 'chart':
      return chartIcon
    case 'build':
      return buildIcon
    case 'gear':
      return gearIcon
    case 'network':
      return networkIcon
    case 'office':
      return officeIcon
    case 'team':
      return teamIcon
    default:
      return chartIcon
  }
}

const CardContainer = styled.div`
  background: ${colors.brand};
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  max-width: 450px;
  min-width: 320px;
  width: 100%;
  margin: 0 10px 20px;
  transition: all 0.3s ease-out;
  padding: 30px;
`

const ItemWrap = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  justifyContent: 'space-between'
})
const ListText = styled('div')({
  fontSize: 20,
  fontWeight: 400,
  opacity: 0.6,
  flex: 1,
  color: 'white'
})

const ImageWrap = styled('div')({
  width: 45,
  height: 45,
  backgroundColor: colors.brandDark,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center'
})

const ListImage = styled.img`
  object-fit: content;
  height: 30px;
  width: 30px;
`
// const Grid = styled('div')({
//   display: 'grid',
//   gridTemplateColumns: 'repeat(4, 1fr)',
//   gridGap: 20,
//   '@media (max-width: 768px)': {
//     gridTemplateColumns: 'repeat(1, 1fr)'
//   }
// })

interface IListItem {
  title: string
  Icon: string
}

const ListItem = ({ title, Icon }: IListItem) => {
  const salProps = useSalProps()
  return (
    <CardContainer {...salProps}>
      <ItemWrap>
        <ImageWrap>
          <Icon />
        </ImageWrap>
        <ListText>{title}</ListText>
      </ItemWrap>
    </CardContainer>
  )
}

const List = ({ items }: { items: IListItem[] }) => {
  return (
    <>
      {items.map((item, index) => (
        <>
          <ListItem key={index} title={item.title} Icon={iconMapper(item.icon)} />
        </>
      ))}
    </>
  )
}

export default List

// export interface ICardProps {
//   title: string
//   content: string
//   icon: string
//   [x: string]: any
// }

// const CardContainer = styled.div`
//   background: ${colors.brand};
//   box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
//   border-radius: 5px;
//   max-width: 450px;
//   min-width: 320px;
//   width: 100%;
//   padding: 50px 50px 20px 50px;
//   margin: 0 10px 20px;
//   transition: all 0.3s ease-out;
// `
// const CardHeading = styled.h3`
//   color: ${colors.white};
//   position: relative;
//   padding-top: 0.5em;
//   margin: 2em 0 1em;
//   font-size: ${getEmSize(24)}em;
//   &:after {
//     content: ' ';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 50px;
//     background: ${colors.white};
//     height: 2px;
//   }
// `

// const CardServices: React.FC<ICardProps> = ({ width, title, content, icon, ...props }) => (
//   <CardContainer {...props}>
//     <div
//       css={css`
//         height: 170px;
//         display: flex;
//         align-items: center;
//       `}
//     >
//       {getIcon(icon)}
//     </div>
//     <CardHeading>{title}</CardHeading>
//     <p
//       css={{
//         color: colors.white,
//         opacity: 0.6,
//         fontSize: `${getEmSize(20)}em`
//       }}
//     >
//       {content}
//     </p>
//   </CardContainer>
// )
