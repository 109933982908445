import React, { Fragment, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { CaseStudyCard } from '../../components/case-study/Card'
import { Heading } from '../../components/case-study/Heading'
import { BigParagraph, CaseStudyParagraph } from '../../components/case-study/Paragraph'
import { ListItem } from '../../components/case-study/ListItem'
import { colors } from '../../styles/variables'
import jsonData from './data.json'
import HeadLineCard from '../../components/case-study/HeadLineCard'
import 'modern-normalize'
import '../../styles/normalize'
import ActivityCard from '../../components/case-study/ActivityCard'
import OutcomeCard from '../../components/case-study/OutComeCard'
import Activity from '../../components/case-study/V2/Activity'
import CaseStudyLayout from '../../layouts/case-study/[id]'
import OutCome from '../../components/case-study/V2/Outcome'
import Headline from '../../components/case-study/V2/HeadLine'

import data from '../../components/case-study/data/data.json'
import Head from '../../components/case-study/helpers/Head'
import Header from '../../components/Header'
import Story from '../../components/case-study/V2/Story'
import Dilligence from '../../components/case-study/V2/Dilligence'

const CaseStudyPage = ({ id }: { id: string }) => {
  const currentData = data?.find((d: any) => String(d.slug) === id)
  if (!currentData) {
    return <>Not found</>
  }
  const [headerLinks] = useState(currentData.nav)

  return (
    <CaseStudyLayout title={`${currentData?.headline?.topTitle}`}>
      <Header closeTo="/" title={currentData?.headline?.topTitle} links={headerLinks} />

      <Headline
        id="overview"
        title={currentData?.headline?.title}
        topTitle={currentData.headline?.topTitle}
        description={currentData?.headline?.description}
      />
      <Activity
        id="activity"
        title={currentData?.activity?.topTitle}
        description={currentData?.activity?.description}
        helperDescription={currentData?.activity?.helperDescription}
        items={currentData?.activity?.list}
        isList={currentData?.activity?.isList}
        content={currentData?.activity?.content}
      />
      {currentData?.dilligence && (
        <Dilligence id="dilligence" title={currentData?.dilligence?.topTitle} description={currentData?.dilligence?.description} />
      )}
      {currentData?.obstaclesuccess && <Story {...currentData?.obstaclesuccess} />}
      <OutCome id="outcome" title={currentData?.outcome.topTitle} description={currentData?.outcome.description} />
    </CaseStudyLayout>
  )
}

export default CaseStudyPage
