import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'
import css from '@emotion/css'
import { colors, mq, widths } from '../../../styles/variables'
import SectionContainer from '../../SectionContainer'
import { getEmSize } from '../../../styles/mixins'

export const ContainerWrap = styled(SectionContainer)`
  background-color: ${colors.brandDark};
  flex-direction: column;
  justify-content: center;
  min-height: 800px;
  overflow: hidden;
`

export const ContainerInner = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin: 50px auto 50px;
  max-width: ${widths.maxInner}px;
  z-index: 100;
  ${mq('md')} {
    margin: 50px auto 50px;
  }
  ${mq('lg')} {
    margin: 100px auto 50px;
  }
`

export const CenteredContent = styled(Box)`
  padding: 30px;
  ${mq('md')} {
    padding: 50px;
  }
`

export const SectionHeading = styled.h2`
  text-transform: uppercase;
  color: ${colors.white};
  position: relative;
  padding-top: 0.5em;
  margin-bottom: 1em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    background: ${colors.white};
    height: 3px;
  }
`

export const Wrap = styled.div`
  max-width: 100%;
  ${mq('md')} {
    max-width: 70%;
  }
  h3 {
    margin-bottom: 1em;
    &.subTitle {
      margin-top: 0;
    }
  }
  h3,
  blockquote {
    font-size: 28px;
    font-weight: 400;
    max-width: 100%;
    ${mq('md')} {
      font-size: 30px;
    }
  }
`

export const TextTag = styled.div``

export const renderTextTag = (data: any, index: number, salProps: any) => {
  const tag = data.tag ? data.tag : 'p'
  const isQuote = data.tag === 'blockquote' || data.isQuote
  return (
    <div key={index} {...salProps}>
      <TextTag
        as={tag}
        css={{
          color: data?.color ? data.color : 'inherit',
          fontWeight: data?.fontWeight || 'inherit',
          fontStyle: data?.isQuote ? 'italic' : 'none',
          fontSize: data?.fontSize ? data.fontSize : '1.3em'
        }}
        data-sal-anim="200"
        key={index}
      >
        {isQuote && '“'}
        {data.text}
        {isQuote && '”'}

        {(data.tag === 'ul' || data.tag === 'ol') &&
          data.list.map((item, index) => (
            <li
              key={index}
              css={{
                color: data?.color ? data.color : 'inherit',
                fontWeight: data?.fontWeight || 'inherit',
                fontStyle: data?.isQuote ? 'italic' : 'none',
                fontSize: data?.fontSize ? data.fontSize : 'inherit'
              }}
            >
              {item}
            </li>
          ))}

        {data?.image ? (
          <img
            src={data.image}
            css={css`
              margin-top: 2em;
              margin-right: -500px;
            `}
          />
        ) : null}
      </TextTag>

      {data?.isQuote && (
        <div
          css={css`
            font-weight: 400;
            font-size: 16px;
          `}
        >
          {data?.author}
        </div>
      )}
    </div>
  )
}

interface IContainerProps {
  backgroundColor?: string
  id?: string
}

export const Container: React.FC<IContainerProps> = ({ children, backgroundColor = colors.brandDark, id = '' }) => {
  return (
    <ContainerWrap css={{ backgroundColor }} id={id}>
      <ContainerInner>
        <CenteredContent width={[1, 9 / 10, 4 / 5]}>{children}</CenteredContent>
      </ContainerInner>
    </ContainerWrap>
  )
}
