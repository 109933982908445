import styled from '@emotion/styled'
import { Fragment } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import { colors, mq } from '../../../styles/variables'
import { Container, SectionHeading, Wrap } from '../helpers/ContainerV2'
import { useSalProps } from '../../../helpers/useSalAnim'
import { getEmSize } from '../../../styles/mixins'
import { BannerContainer, CenteredContent } from '../../HeroBanner'

const HeadingTitle = styled('div')({
  fontWeight: 600,
  fontSize: 55,
  color: colors.brand,
  marginTop: 20,
  lineHeight: 1.3
  // transition: 'all 0.5s ease-out'
})

interface IHeadlineProps {
  id: string
  title: string
  topTitle: string
  description: string[]
}

const Headline = ({ title, topTitle, description }: IHeadlineProps) => {
  const salProps = useSalProps()
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero_banner.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage Tag="section" id="overview" fluid={imageData} backgroundColor="#040e18">
      <BannerContainer>
        <CenteredContent p={50} width={[1, 9 / 10, 4 / 5]}>
          <SectionHeading
            css={{
              color: colors.brand,
              '&:after': {
                backgroundColor: colors.brand
              }
            }}
            {...salProps}
          >
            {topTitle}
          </SectionHeading>
          <HeadingTitle
            css={{
              color: colors.brand,
              marginBottom: '30px',
              fontSize: `${getEmSize(35)}em`,
              [mq('md')]: {
                fontSize: `${getEmSize(40)}em`
              },
              [mq('lg')]: {
                fontSize: `${getEmSize(45)}em`
              },
              [mq('xl')]: {
                fontSize: `${getEmSize(55)}em`
              },
              [mq('xxl')]: {
                fontSize: `${getEmSize(70)}em`
              }
            }}
            {...salProps}
          >
            {title}
          </HeadingTitle>
          <br />
          <p
            css={{
              fontSize: `${getEmSize(24)}em`,
              maxWidth: 600
            }}
            {...salProps}
            data-sal-anim="200"
          >
            {description?.map((d, index: number) => {
              return (
                <Fragment key={index}>
                  {d}
                  <br />
                  <br />
                </Fragment>
              )
            })}
          </p>
        </CenteredContent>
      </BannerContainer>
    </BackgroundImage>
  )
}

export default Headline
