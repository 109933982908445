import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { colors } from '../../../styles/variables'
import { Container, SectionHeading, Wrap } from '../helpers/ContainerV2'
import { useSalProps } from '../../../helpers/useSalAnim'
import { getEmSize } from '../../../styles/mixins'
import CardTeam from '../../CardTeam'

export const Flex = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 768px)': {
    display: 'block'
  }
})
const ContactTextHelper = styled('div')({
  fontSize: 19,
  color: colors.gray.calm,
  fontWeight: 500,
  marginBottom: 10
})

const ContactLink = styled('a')({
  textDecoration: 'none'
})

interface IOutComeProps {
  id: string
  title: string
  description: string[]
}
const OutCome = ({ title, description, id }: IOutComeProps) => {
  const salProps = useSalProps()
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "team/Justin.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <Container backgroundColor={colors.gray.copy} id={id}>
      <SectionHeading {...salProps}>{title}</SectionHeading>

      <Flex
        css={{
          gap: 70,
          alignItems: 'flex-start'
        }}
      >
        <Wrap>
          {description?.map((text, index) => {
            return (
              <p
                css={{
                  fontSize: `${getEmSize(24)}em`,
                  color: colors.white
                }}
                {...salProps}
                data-sal-anim="200"
                key={index}
              >
                {text}
              </p>
            )
          })}
        </Wrap>
        <div>
          <CardTeam name="Justin Dymock" position="Partner - Technology Services" image={imageData} linkedIn="" />
          <br />
          <ContactTextHelper {...salProps}>
            Further information contact:
            <br />
          </ContactTextHelper>
          <ContactLink href="mailto:justin.dymock@rumbleasia.com" target="_blank" rel="noopener noreferrer">
            <ContactTextHelper {...salProps}>justin.dymock@rumbleasia.com</ContactTextHelper>
          </ContactLink>
        </div>
      </Flex>
    </Container>
  )
}

export default OutCome
